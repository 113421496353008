import React, { useState } from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import { graphql } from 'gatsby'
import Promos from 'components/promos'
import H2 from 'components/typography/h2'
import { Helmet } from 'react-helmet'
import stihlData from 'data/stihl-data'
import Layout from 'components/layout'
import Content from 'components/new/content'
import SeriesTableGrid from 'components/category/series-table-grid'
import BreadcrumbsScript from 'components/scripts/breadcrumbs'
import YouTube from 'components/youtube'
import Section from 'components/section'
import { getSrc } from 'gatsby-plugin-image'
import getSeriesSpecsTables from 'utils/get-series-specs-tables'

const StihlSubcategory = props => {
  const {
    data: {
      allHutsonPromotion,
      allSanityStihlEquipment,
      heroImage,
      sanityStihlEquipmentCategory,
      sanityStihlEquipmentSubcategory,
    },
  } = props

  const [{ breadcrumbs, meta, subcategoryData, tables }] = useState(() => {
    const breadcrumbs = [
      {
        name: 'Home',
        schemaName: 'Hutson Inc',
        link: '/',
      },
      {
        name: 'STIHL',
        link: '/stihl/',
      },
      {
        name: sanityStihlEquipmentCategory.title,
        link: `/stihl/${sanityStihlEquipmentCategory.handle}/`,
      },
      {
        name: sanityStihlEquipmentSubcategory.title,
        link: `/stihl/${sanityStihlEquipmentCategory.handle}/${sanityStihlEquipmentSubcategory.handle}/`,
      },
    ]

    const tables = getSeriesSpecsTables(
      allSanityStihlEquipment.nodes.map(node => {
        return {
          name: node.title,
          slug: node.slug,
          image: getSrc(node.firstImage.asset.gatsbyImageData),
          seriesTableData: node.tableSpecs,
        }
      }),
      `Compare ${sanityStihlEquipmentSubcategory.title}`
    )

    const meta = {
      title: `${sanityStihlEquipmentSubcategory.title} | Hutson Inc`,
      keywords: [
        sanityStihlEquipmentCategory.title,
        sanityStihlEquipmentSubcategory.title,
      ].toString(),
      itemList: JSON.stringify({
        '@context': 'http://schema.org',
        '@type': 'ItemList',
        'numberOfItems': allSanityStihlEquipment.length,
        'itemListElement': allSanityStihlEquipment.nodes.map((product, index) => ({
          '@type': 'ListItem',
          'position': index + 1,
          'url': `https://www.hutsoninc.com${product.slug}`,
        })),
      }),
    }

    const subcategoryData = stihlData.find(
      obj => obj.subcategory === sanityStihlEquipmentSubcategory.handle
    )

    if (subcategoryData) {
      meta.description = subcategoryData.metaDescription
    } else {
      meta.description = `Check out STIHL ${sanityStihlEquipmentSubcategory.title} at Hutson.`
    }

    return { breadcrumbs, meta, subcategoryData, tables }
  })
  return (
    <Layout>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
        <meta name='keywords' content={meta.keywords} />
        <script type='application/ld+json'>{meta.itemList}</script>
      </Helmet>

      <BreadcrumbsScript breadcrumbs={breadcrumbs} />

      <Hero
        title={sanityStihlEquipmentSubcategory.title}
        image={heroImage.childImageSharp.gatsbyImageData}
        breadcrumbs={breadcrumbs}
        overlayOpacity={0.15}
      />

      <Content kind='full'>
        <SeriesTableGrid tables={tables} />

        {subcategoryData && subcategoryData.description && (
          <Section>{subcategoryData.description}</Section>
        )}

        <Promos data={allHutsonPromotion.nodes} type={sanityStihlEquipmentSubcategory.title} />

        {subcategoryData && subcategoryData.videoId && (
          <Section>
            <H2>Videos</H2>
            <YouTube videoId={subcategoryData.videoId} list={subcategoryData.videoList} />
          </Section>
        )}
      </Content>
    </Layout>
  )
}

const Hero = styled(HeroImageTitle)`
  height: 350px;
`

export const pageQuery = graphql`
  query stihlSubcategoryTemplate(
    $category: String!
    $subcategory: String!
    $heroImagePath: String!
  ) {
    heroImage: file(relativePath: { eq: $heroImagePath }) {
      ...FullWidthImage
    }
    allSanityStihlEquipment(
      filter: { subcategory: { handle: { eq: $subcategory } }, published: { eq: true } }
      sort: { fields: [title], order: ASC }
    ) {
      nodes {
        firstImage {
          asset {
            gatsbyImageData(width: 640)
          }
        }
        slug
        tableSpecs {
          data
          property
        }
        title
      }
    }
    sanityStihlEquipmentCategory(handle: { eq: $category }) {
      handle
      title
    }
    sanityStihlEquipmentSubcategory(handle: { eq: $subcategory }) {
      handle
      title
    }
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $subcategory }, type: { eq: "stihl" } } }
      }
      sort: { fields: [endDate], order: DESC }
    ) {
      nodes {
        ...HutsonPromo
      }
    }
  }
`

export default StihlSubcategory
