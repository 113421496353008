import React from 'react'

const stihlData = [
  {
    subcategory: 'battery-saws',
    metaDescription:
      "STIHL battery chainsaws are quieter than gasoline chainsaws, start with the squeeze of a trigger, and don't need to be plugged into an outlet to run. Learn more about STHL battery chainsaws at Hutson.",
  },
  {
    subcategory: 'farm-and-ranch-saws',
    metaDescription:
      'STIHL Farm & Ranch saws were built to be dependable and flexible, making them ideal for a wide range of uses. See what features these saws are packing at Hutson.',
  },
  {
    subcategory: 'homeowner-saws',
    metaDescription:
      'Need a chainsaw to work around your home? STIHL Homeowner Saws were built with your needs in mind. Find the right chainsaw for the job at Hutson.',
  },
  {
    subcategory: 'in-tree-saws',
    metaDescription:
      'Lightweight and powerful, the STIHL In-Tree Saws were built to be used 100+ feet off the ground, making maneuvering a breeze. Compare models and find the right chainsaw for you at Hutson.',
  },
  {
    subcategory: 'professional-saws',
    metaDescription:
      'Professionals in farming, land clearing, and logging know and trust STIHL chainsaws. Built with custom saw chains and guide bars, these chainsaws are guaranteed to perform. Find the perfect chainsaw for your work at Hutson.',
  },
  {
    subcategory: 'professional-concrete-cutters',
    metaDescription:
      'STIHL Professional Concrete Cutters cut through concrete, cinder blocks, asphalt, and other materials with ease. Cutting depths up to 15.7 inches, plus their small and lightweight design makes them perfect for any construction or renovation job. Find the right Professional Concrete Cutter at Hutson.',
  },
  {
    subcategory: 'battery-cut-off-machines',
    metaDescription:
      "STIHL built the world's first battery cut-off machine. Powerful, lightweight, and portable make it extremely versatile around the job site. Learn more about STIHL Battery Cut-Off Machines at Hutson.",
  },
  {
    subcategory: 'professional-cut-off-machines',
    metaDescription:
      "STIHL Professional Cut-Off Machines were built to work. With their X2 air filtration system, fuel-efficient engine, and ergonomic grip, you'll have to look for a reason to put them down. Find the ideal STIHL cut-off machine for your job at Hutson.",
  },
  {
    subcategory: 'homeowner-edgers',
    metaDescription:
      'Keep your yard where it belongs with STIHL edgers. Your sidewalk will thank you. Built with an ergonomic design, adjustable depth wheel, and fuel-efficient engine, STIHL edgers are second to none. Learn more about STIHL edgers at Hutson.',
  },
  {
    subcategory: 'professional-edgers',
    metaDescription:
      'Fuel-efficiency, balanced design, and easy maintenance are just a few characteristics that professionals are looking for in an edger. STIHL packs these features and so much more into their edgers. Learn more about professional STIHL edgers at Hutson.',
  },
  {
    subcategory: 'professional-pressure-washers',
    description: (
      <p>
        Need a heavy-duty pressure washer to power through all your tough clean-up jobs? The STIHL
        commercial pressure washers deliver over 3,200 - 4,200 PSI to put caked on mud, dirt, and
        debris back where it belongs. These gas pressure washers have commercial-grade features like
        low oil shutdown protection, 40 - 50 ft steel braided hoses, ceramic sleeve pistons, and
        ergonomic, adjustable handles. Whether you’re removing tough stains from your walkways or
        taking a months’ worth of mud off your tractor, STIHL has you covered.
      </p>
    ),
    metaDescription:
      'Whether you’re removing set-in stains from your sidewalk or taking a month’s worth of mud off your tractor, STIHL commercial pressure washers have the power you need.',
    videoId: 'yjdgX4ihY_0',
    videoList: 'PLUz5fj7f_mw8GcPcirAc5KQ89IWX7U33P',
  },
  {
    subcategory: 'homeowner-pressure-washers',
    description: (
      <p>
        For a lighter use power washer under 3,000 PSI, rely on the STIHL home pressure washers.
        These models are ideal for cleaning cars, lawn mowers, driveways, siding, decks, patio
        furniture, and fencing. All STIHL pressure washers include easy start pump systems and
        standard detergent injector systems. For those harder to reach places, ask about our STIHL
        pressure washer accessories, including pivot couplers, hose and wand extensions, gutter
        cleaners, and surface cleaners.
      </p>
    ),
    metaDescription:
      'STIHL homeowner pressure washers have up to 3,000 PSI and are ideal for cleaning siding, driveways, fencing, cars, lawn mowers, and patio furniture.',
    videoId: 'wp1gn8GwaQA',
    videoList: 'PLUz5fj7f_mw_M8BbEqXOla1dHVIYnfrrS',
  },
  {
    subcategory: 'homeowner-lawn-mowers',
    description: (
      <p>
        STIHL push mowers are great for homeowners looking to maintain a small yard, keep their
        backyard mowed, or reach tight spaces that riding mowers can’t. These cordless lawn mowers
        use the STIHL battery system, which is compatible with other STIHL battery-powered tools.
        They weigh less than gas push mowers, making it easier to maneuver around the yard. Start
        with the push of a button and go, no more worrying about gas or oil. STIHL lawn mowers are
        ideal for lawns around or under .25 acres, but they can also handle .5 acres or more with
        extra batteries. Push and self-propelled lawn mowers are available in 19 and 21-in deck
        sizes.
      </p>
    ),
    metaDescription:
      'Lightweight, quiet, highly maneuverable, and easy to maintain, STIHL battery-powered lawn mowers are a game changer. Self-propelled and push-type models are available in 19 and 21-in deck sizes. These cordless push mowers use the STIHL battery system, which works with other STIHL battery tools.',
    videoId: 'gI1gHWUwsWs',
    videoList: 'PLUz5fj7f_mw8B0UyHOf2QsuFhvPncATfl',
  },
  {
    subcategory: 'robotic-mowers',
    description: (
      <>
        <p>
          Your lawn can’t mow itself, but STIHL robotic mowers can do the job for you. STIHL’s iMOW
          robotic lawn mowers can be programmed to mow a variety of yard layouts, front or back, at
          whatever time you choose. These mowers will keep lawns up to 1 acre, or .37 acres with the
          RMI 422 P model, maintained automatically so you have a perfect looking yard year-round.
          Spend time doing other things without paying someone to mow for you.
        </p>
        <p>
          STIHL iMOW bots are set with a user-defined PIN number that cannot be changed, even if the
          unit is reset, to reduce the possibility of theft. If the iMOW bumps into an obstacle, it
          will turn and continue mowing around it. They feature a low profile, giving them a 0.8 to
          2.4-in cutting height and preventing them from driving over debris. Safety features are
          built in to turn the blades off whenever the unit is lifted.
        </p>
      </>
    ),
    metaDescription:
      'Your lawn can’t mow itself, but STIHL robotic mowers can automate the job for you. Programmable for different yard layouts, these mowers can maintain up to an acre year-round so you can spend time doing other things.',
    videoId: 'hqiNYRrR32M',
    videoList: 'PLUz5fj7f_mw-pn1n1Sjk_ChqMJXlVQCTx',
  },
]

export default stihlData
